@import url("https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap");
.container {
  max-width: 1750px;
}
body {
  font-family: "Lato", sans-serif !important;
}
p,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0px;
  padding: 0px;
}
a {
  text-decoration: none;
}
.site-header {
  padding: 20px 0px;
  position: absolute;
  width: 100%;
}
.site-header .topbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.site-header .topbar p {
  color: #fff;
}
.site-header .topbar a {
  color: #fff;
  text-decoration: none;
}
.site-header .navbar-brand a img {
  width: 200px;
  padding: 40px 0px;
}
.site-header .menu {
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  padding: 0px;
}
.menu.scrolled {
  background: #000;
  opacity: 0.8;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 99;
}
.site-header .menu a.nav-link {
  font-size: 15px;
  font-weight: 700;
  text-transform: uppercase;
  line-height: 24.375px;
  letter-spacing: 0.6px;
  color: #fff;
  padding: 25px 0px 38px 0px;
  border-style: solid;
  border-width: 4px 0 0;
  border-color: transparent;
  margin-right: 55px;
}
.site-header .menu a.nav-link.active {
  border-color: #f7931d;
}
section.home-hero {
  /* background-image: url(../../../public/img/hero.jpeg);
  background-repeat: no-repeat;
  background-size: cover; */
  /* margin-top: -382px; */
  margin-bottom: 0;
  /* padding: 439px 0 301px; */
  /* height: 100vh; */
  height: 900px;
  width: 100%;
  z-index: 2;
  display: flex;
  align-items: center;
}
.home-hero video {
  object-fit: cover;
  object-position: center;
}
.home-hero video,
.home-hero::after {
  /* height: 100%; */
  height: 900px;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: -1;
}
.home-hero::after {
  background-color: #000;
  content: "";
  opacity: 0.7;
}
section.home-hero .hero-content {
  max-width: 1000px;
  margin: auto;
}
section.home-hero .hero-content h1 {
  color: #fff;
  font-weight: 900;
  text-transform: uppercase;
  line-height: 79.8px;
  letter-spacing: 2.8px;
  font-size: 70px;
  margin-bottom: 20px;
}
section.home-hero .hero-content p {
  color: #fff;
  font-size: 20px;
  font-weight: 400;
  letter-spacing: 0.64px;
  width: 500px;
  max-width: 500px;
  margin-bottom: 45px;
}
section.home-hero .hero-content a {
  font-size: 13px;
  font-weight: 700;
  text-transform: uppercase;
  line-height: 25px;
  letter-spacing: 0.64px;
  background-color: #f7931d;
  border-style: solid;
  border-width: 2px;
  border-color: #f7931d;
  border-radius: 30px 30px 30px 30px;
  padding: 14px 38px 13px;
  color: #fff;
  text-decoration: none;
}
section.shipping {
  background-color: #122134;
  padding: 55px 0 32px;
}
section.shipping .Heading {
  margin-bottom: 20px;
}
section.shipping .Heading img {
  width: 40px;
}
section.shipping .Heading h1 {
  color: #8cb1de;
  font-family: "Lato", sans-serif;
  font-size: 32px;
  font-weight: 700;
  line-height: 1.31em;
  letter-spacing: 1.28px;
  margin-bottom: 10px;
}
section.shipping .jet-services__inner {
  background-color: #2962ff;
  padding: 0 0 40px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-flow: column nowrap;
  -ms-flex-flow: column nowrap;
  flex-flow: column nowrap;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}
section.shipping .jet-services__inner .img img {
  width: 100%;
  height: 403px;
  object-fit: cover;
}
section.shipping .jet-services__inner .content {
  text-align: center;
  margin-top: 40px;
}
section.shipping .jet-services__inner .content h1 {
  color: #fff;
  font-size: 30px;
  font-weight: 700;
  text-transform: uppercase;
  line-height: 35.1px;
  letter-spacing: 1.2px;
}
section.shipping .jet-services__inner .content p {
  color: #fff;
}
section.shipping .col-lg-3 {
  padding: 0px;
}
section.work .card {
}

section.work .card {
  display: flex;
  height: 350px;
  padding: 55px 36px;
  align-items: flex-start;
  gap: 10px;
  border-radius: 14.742px;
  border: 0.921px solid rgba(0, 0, 0, 0.05);
  transition: border 0.5s ease;
  background: rgba(255, 255, 255, 0.35);
  margin-bottom: 20px;
}
section.work .card:hover {
  border: 0.921px solid #003cff;
  background: #fff;
  box-shadow: 0px 3.685px 114.249px 9.214px rgba(0, 0, 0, 0.04);
}
section.work .card h4 {
  font-family: "Lato", sans-serif;

  font-size: 30px;
  font-weight: 500;
  line-height: 48.41px;
  letter-spacing: -0.02em;
  text-align: left;
  margin-bottom: 20px;
}
section.work .card h4 span {
  background-color: #f7931d;
  border-radius: 100%;
  width: 50px;
  height: 50px;
  display: inline-flex;
  /* display: flex; */
  justify-content: center;
  align-items: center;
  color: #fff;
  font-weight: bold;
  margin-right: 10px;
}

section.work .card p {
  font-family: "Lato", sans-serif;

  font-size: 16px;
  font-weight: 400;
  margin: 0px;
}

/* section.work .col-lg-4 {
  padding: 0px;
} */
section.work .work-list {
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 781px;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
}
section.work .work-list-one {
  /* background-image: url(../../../public/img/index-6.jpg); */
}
section.work .work-list-two {
  /* background-image: url(../../../public/img/index-7.jpg); */
}
section.work .work-list-three {
  /* background-image: url(../../../public/img/index-8.jpg); */
}

section.work .work-list .content {
  max-width: 380px;
}

section.work .work-list .content svg {
  margin-bottom: 20px;
}
section.work .work-list .content h1 {
  margin-bottom: 20px;
  font-weight: 700;
}
section.Counter {
  padding: 100px 0px;
  position: relative;
  /* background-image: url(../../../public/img/plane.jpeg); */
  /* background-repeat: no-repeat; */
  /* background-size: cover; */
  /* background-attachment: fixed; */
  /* background-position: top; */
}

section.Counter .backimg::before {
  background-color: #000;
  opacity: 0.6;
  position: absolute;
  width: 100%;
  height: 100%;
  content: "";
}
section.Counter .backimg {
  position: absolute;
  min-height: 403px;
  top: 0px;
  left: 0px;
  z-index: -1;
  right: 0px;
}
section.Counter .backimg img {
  height: 403px;
  width: 100%;
  object-fit: cover;
}

/* section.Counter::before {
  background-color: #000;
  opacity: 0.8;
  position: absolute;
  width: 100%;
  height: 100%;
  content: "";
} */
section.Counter .content {
  /* display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1000px;
  margin: auto; */
  text-align: center;
  text-align: -webkit-center;
  text-align: -moz-center;
  margin-bottom: 20px;
}
section.Counter .content h4 {
  color: #fff;
  font-family: "Lato", sans-serif;
  font-size: 30px;
  font-weight: 700;
  /* line-height: 72px; */
  letter-spacing: 2.4px;
  padding: 20px 0px;
  /* border-bottom: 3px solid #fff; */
  /* width: 360px; */
  height: 115px;
  margin-bottom: 20px;
}
section.Counter .content p {
  color: #fff;
  font-family: "Lato", sans-serif;
  font-size: 20px;
  font-weight: 700;
  line-height: 24.4px;
  letter-spacing: 0.8px;
  border-bottom: 3px solid #fff;
  padding: 20px 0px;
}

section.Contact {
  /* background-image: url(../../../public/img/index9.jpeg); */
  /* background-image: url(./img); */
  /* background-repeat: no-repeat; */
  /* background-size: cover; */
  min-height: 700px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  /* background-attachment: fixed; */
  /* background-position: right; */
}
section.Contact .backimg::before {
  background-color: #000;
  opacity: 0.6;
  position: absolute;
  width: 100%;
  height: 100%;
  content: "";
}
section.ListHero .backimg::before {
  opacity: 0.8;
}
section.Contact .backimg {
  position: absolute;
  min-height: 700px;
  top: 0px;
  left: 0px;
  right: 0px;
}
section.Contact .backimg img {
  height: 700px;
  width: 100%;
  object-fit: cover;
}
section.Contact .content {
  max-width: 1000px;
  margin: auto;
  text-align: center;
  text-align: -webkit-center;
  text-align: -moz-center;
  position: relative;
}
section.Contact .content h4 {
  color: #fff;
  font-size: 45px;
  font-weight: 700;
  line-height: 52.65px;
  letter-spacing: 1.8px;
  margin-bottom: 40px;
}
section.Contact .content a {
  font-size: 13px;
  font-weight: 700;
  text-transform: uppercase;
  line-height: 25px;
  letter-spacing: 0.64px;
  background-color: #f7931d;
  border-style: solid;
  border-width: 2px;
  border-color: #f7931d;
  border-radius: 30px 30px 30px 30px;
  padding: 14px 38px;
  color: #fff;
}
section.Client .client-list {
  background-color: #192b40;
  margin: 0 29px 0 30px;
  border-radius: 2px 2px 2px 2px;
  padding: 30px 0px;
  display: flex;
  justify-content: center;
  /* align-items: center; */
  min-height: 1000px;
}

/* section.Client .client-list .content .img {
  margin-bottom: 40px;
  width: 190px;
  height: 190px;
  border-radius: 100px;
} */
section.Client .client-list .content {
  padding: 0px 40px;
  text-align: center;
  text-align: -webkit-center;
  text-align: -moz-center;
}
section.Client .client-list .content .img img {
  width: 190px;
  height: 190px;
  border-radius: 100px;
  object-fit: cover;
  margin-bottom: 40px;
}
section.Client .client-list .content p {
  color: #7f9ac5;
  font-size: 17px;
  font-weight: 300;
  line-height: 27.2px;
  /* padding: 0px 40px; */
  letter-spacing: 0.68px;
  /* width: 350px; */
  margin-bottom: 30px;
}
section.Client .client-list .content h4 {
  color: #fff;
  font-size: 17px;
  font-weight: 300;
  line-height: 27.2px;
  letter-spacing: 0.68px;
  /* width: 350px; */
  margin-bottom: 20px;
}
section.Client .client-list .content h1 {
  color: #fff;
  font-size: 24px;
  font-weight: 700;
  line-height: 27.2px;
  letter-spacing: 0.68px;
  /* width: 350px; */
  margin-bottom: 10px;
}
section.Blog {
  padding: 55px 0 32px;
}
section.Blog .Heading h1 {
  font-family: "Lato", sans-serif;
  font-size: 32px;
  font-weight: 700;
  line-height: 1.31em;
  letter-spacing: 1.28px;
  margin-bottom: 10px;
}
section.Blog .Heading {
  margin-bottom: 20px;
}
section.Blog .Heading img {
  width: 40px;
}
section.Blog .blog-list img {
  height: auto;
  max-width: 100%;
  border: none;
  -webkit-border-radius: 0;
  border-radius: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
  margin-bottom: 20px;
  border-radius: 10px;
}
section.Blog .blog-list a {
  font-family: "Lato", sans-serif;
  font-size: 20px;
  font-weight: 700;
  text-align: left;
  margin: 0;
  margin-bottom: 20px;
  display: block;
  color: #f7931d;
}
section.Blog .blog-list p {
  color: #132738;
  font-family: "Lato", sans-serif;
  font-size: 16px;
  font-weight: 300;
  line-height: 1.625em;
  letter-spacing: 0.04px;
  text-align: left;
}
section.Blog .readall {
  margin-top: 40px;
}
section.Blog .readall a {
  font-size: 13px;
  font-weight: 700;
  text-transform: uppercase;
  line-height: 25px;
  letter-spacing: 0.64px;
  fill: #000;
  color: #000;
  background-color: #fff;
  border-style: solid;
  border-width: 2px;
  border-color: #cce3f4;
  border-radius: 30px 30px 30px 30px;
  padding: 12px 34px;
  margin-top: 40px;
}
section.Routes {
  /* background-color: #283544; */
  /* margin-bottom: 80px; */
  margin-bottom: -10px;
  /* margin: -1px -5px -10px -5px; */

  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;
  max-width: 100%;
  background: #2a3444;
}
section.Routes video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.Blog-Detail h4 {
  font-family: "Lato", sans-serif;
  font-size: 32px;
  font-weight: 700;
  line-height: 1.31em;
  letter-spacing: 1.28px;
  margin-bottom: 10px;
}
.Blog-Detail h5 {
  font-family: "Lato", sans-serif;
  font-size: 24px;
  font-weight: 700;
  line-height: 1.31em;
  letter-spacing: 1.28px;
  margin-bottom: 10px;
}
.Blog-Detail p {
  color: #000 !important;
  font-size: 17px !important;
  font-weight: 400 !important;
  line-height: 27.2px !important;
  letter-spacing: 0.68px !important;
  margin-bottom: 30px !important;
}
.Blog-Detail ul li h6 {
  font-family: "Lato", sans-serif;
  font-size: 20px;
  font-weight: 700;
  line-height: 1.31em;
  letter-spacing: 1.28px;
  margin-bottom: 10px;
}
.Blog-Detail ul {
  margin-bottom: 30px;
}
.Blog-Detail ul li h6 span {
  color: #000 !important;
  font-size: 17px !important;
  font-weight: 400 !important;
  line-height: 27.2px !important;
  letter-spacing: 0.68px !important;
  margin-bottom: 30px !important;
}
.getquote {
  padding: 80px 30px !important;
}
.getquote .content h1 {
  font-family: "Lato", sans-serif;
  font-size: 32px;
  font-weight: 700;
  line-height: 1.31em;
  letter-spacing: 1.28px;
  margin-bottom: 10px;
}
.getquote .content p {
  font-family: "Lato", sans-serif;
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 40px;
}
.getquote .form-group {
  margin-bottom: 20px;
}

.getquote button {
  font-size: 13px;
  font-weight: 700;
  text-transform: uppercase;
  line-height: 25px;
  letter-spacing: 0.64px;
  background-color: #f7931d;
  border-style: solid;
  border-width: 2px;
  border-color: #f7931d;
  border-radius: 30px 30px 30px 30px;
  padding: 14px 38px 13px;
  color: #fff;
  text-decoration: none;
}
.getquote a {
  font-size: 13px;
  font-weight: 700;
  text-transform: uppercase;
  line-height: 25px;
  letter-spacing: 0.64px;
  background-color: #f7931d;
  border-style: solid;
  border-width: 2px;
  border-color: #f7931d;
  border-radius: 30px 30px 30px 30px;
  padding: 14px 38px 13px;
  color: #fff;
  text-decoration: none;
  margin-left: 20px;
  display: inline-block;
}

.getquote .form-group label {
  font-family: "Lato", sans-serif;
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 5px;
}
.getquote .contatcinfo {
  border: 0.921px solid rgba(0, 0, 0, 0.05);
  margin-bottom: 20px;
  border-radius: 10px;
  text-align: center;
  text-align: -webkit-center;
  text-align: -moz-center;
  padding: 20px;
  min-height: 235px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.getquote .contatcinfo svg,
.getquote .contatcinfo img {
  margin-bottom: 10px;
}
.getquote .contatcinfo p {
  font-family: "Lato", sans-serif;
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 10px;
}
.getquote .contatcinfo h4 {
  font-family: "Lato", sans-serif;
  font-size: 18px;
  font-weight: 500;
  letter-spacing: -0.02em;
  /* margin-bottom: 20px; */
}
.getquote .contatcinfo a {
  font-size: 13px;
  font-weight: 700;
  text-transform: uppercase;
  line-height: 25px;
  letter-spacing: 0.64px;
  background-color: #f7931d;
  border-style: solid;
  border-width: 2px;
  border-color: #f7931d;
  border-radius: 30px 30px 30px 30px;
  padding: 14px 38px 13px;
  color: #fff;
  text-decoration: none;
}

footer.custom-footer {
  background-color: #122134;
  border-bottom: 1px solid #c7c7c7;
  margin: auto;
  /* max-width: 1600px; */
  overflow: hidden;
  padding: 50px 102px;
  position: relative;
}

footer.custom-footer .left-content h4 {
  color: #fff;
  font-size: 100px;
  margin: 0;
  text-transform: uppercase;
}

footer.custom-footer .left-content h4 img {
  margin-left: 30px;
}

footer.custom-footer .quicklinks {
  margin-top: 30px;
}

footer.custom-footer .quicklinks h4 {
  color: #fff;
  font-size: 18px;
  font-weight: 700;
  text-decoration: none;
  margin-bottom: 20px;
}

footer.custom-footer .quicklinks ul {
  display: flex;
  gap: 45px;
}

footer.custom-footer .quicklinks ul li a {
  color: #fff;
  font-size: 14px;
  font-weight: 400;
  text-decoration: none;
}

footer.custom-footer .right-content h4,
footer.custom-footer .right-content p {
  color: #fff;
  margin-bottom: 20px;
}

.bottom-footer {
  background-color: #122134;
  border-bottom: 1px solid #c7c7c7;
  margin: auto;
  /* max-width: 1600px; */
  overflow: hidden;
  padding: 20px 102px;
  position: relative;
}

.bottom-footer p {
  color: #fff;
}

@media only screen and (max-width: 1480px) {
  .container {
    padding: 0px 30px;
  }
}
@media only screen and (max-width: 1280px) {
  section.shipping .jet-services__inner {
    min-height: 640px;
  }
  section.Client .client-list .content p {
    width: 100%;
  }
  section.Client .client-list {
    margin: auto;
    padding: 20px;
  }
  .blog-list {
    margin-bottom: 20px;
  }
  footer.custom-footer {
    padding: 30px 0px;
  }
  .bottom-footer {
    padding: 30px;
  }
}
@media only screen and (max-width: 991px) {
  .navbar-toggler {
    background-color: #f7931d;
    position: absolute;
    right: 40px;
    top: -140px;
  }
  .navbar-toggler span {
    filter: invert(1);
  }
  .navbar-collapse.show {
    position: absolute;
    background-color: #122134;
    width: 100%;
    border-radius: 10px;
    top: -7px;
    left: 0px;
  }
  .site-header .menu a.nav-link {
    margin-left: 55px;
    color: #fff;
  }
  .site-header .menu a.nav-link.active {
    border: none;
  }
  section.Counter .backimg {
    height: 100%;
  }
  section.Counter .backimg img {
    height: 100%;
  }
}

@media only screen and (max-width: 768px) {
  section.Client .client-list .content {
    padding: 0px 20px;
  }
  section.Counter .content {
    flex-direction: column;
    gap: 40px;
  }
  section.Contact .content h4 {
    font-size: 34px;
  }
  footer.custom-footer .left-content h4 {
    font-size: 40px;
    margin-bottom: 20px;
  }
  section.home-hero .hero-content h1 {
    font-size: 40px;
    line-height: 60px;
  }
  section.Counter .content h4 {
    width: 100%;
  }
  footer.custom-footer .quicklinks ul {
    gap: 20px;
  }
  .site-header .navbar-brand a img {
    width: 150px;
  }
}
